<template>
  <div class="card flex flex-col" v-if="!hasNotified && ready">
    <div
      class="close rounded-full bg-white mb-0 mr-4 self-end"
      @click="closeCheckbox"
    >
      <mdicon name="close" width="30" height="30" />
    </div>
    <!-- <img
      :src="`https://api.murphysday.com/uploads/images/liff-${payload.value}.png`"
      usemap="#image_map"
      width="1080"
    />
    <map name="image_map">
      <area @click="$emit('close')" coords="847, 39, 957, 147" shape="rect" />
      <area @click="link" coords="181, 1110, 901, 1453" shape="rect" />
    </map> -->
    <div class="relative">
      <div>
        <img
          :class="{ 'px-5': !this.full }"
          :src="payload.day.cardUrl"
          alt=""
        />
      </div>
      <div
        v-if="popID"
        class="absolute w-full bottom-2 text-checkbox text-xl mt-4 mb-2"
      >
        <label>
          <input type="checkbox" v-model="checkbox" />
          一週內不再顯示
        </label>
      </div>
    </div>
  </div>
</template>

<script>
const dateFns = require("date-fns");
import Util from "@/mixins/util";
import $ from "jquery";
const liff = window.liff;

export default {
  name: "Card",
  props: ["popID", "full", "payload"],
  mixins: [Util],
  data: function () {
    return {
      ready: false,
      checkbox: false,
      hasNotified: false,
    };
  },
  mounted() {
    setTimeout(this.resizeMap, 10);
  },
  async created() {
    if (this.popID) {
      let state = await this.getState(this.popID);
      this.hasNotified = state.hasNotified;
      this.ready = true;
    } else {
      this.ready = true;
    }
    // document.title =
    //   "我的莫非牌 " + dateFns.format(this.payload.day.date, "YYYY-MM-DD");
  },
  methods: {
    closeCheckbox() {
      this.hasNotified = true;
      if (this.checkbox) {
        this.setState(this.popID);
      }
      this.$emit("close");
    },
    link() {
      liff.openWindow({
        url: "https://line.me/R/home/public/post?id=010fkvep&postId=1157474591504053514",
        external: false,
      });
    },
    resizeMap() {
      if (!$("<area coords=1>").attr("coords")) return;

      // find all images attached to a map
      var imgs = $("img[usemap]");

      // store initial coords of each <area>
      $("area").each(function (i, v) {
        var area = $(v);
        area.data("coords", area.attr("coords"));
      });

      // on window resize, iterate through each image
      // and scale its map areas
      $(window)
        .bind("resize.scaleMaps", function () {
          imgs.each(function (i, v) {
            scaleMap($(v));
          });
        })
        .trigger("resize.scaleMaps");

      function scaleMap(img) {
        var mapName = img.attr("usemap").replace("#", ""),
          map = $('map[name="' + mapName + '"]'),
          imgScale = img.width() / img.attr("width");

        if (imgScale !== img.data("scale")) {
          map.find("area").each(function (i, v) {
            var area = $(v),
              coords = area.data("coords").split(","),
              newCoords = [];
            $.each(coords, function (j, w) {
              newCoords.push(w * imgScale);
            });
            area.attr("coords", newCoords.join(","));
          });
          img.data("scale", imgScale);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000bf;
  width: 100%;
}
.close {
  // position: fixed;
  // top: 20px;
  // right: 20px;
  font-size: 30px;
}
img {
  max-height: 100%;
  max-width: 100%;
}
.text-checkbox {
  color: #999999;
  input[type="checkbox"] {
    position: relative;
    top: 2px;
    width: 18px;
    height: 18px;
  }
}
</style>
