<template>
  <div v-if="ready" class="birth flex flex-col">
    <div>
      <div class="title">輸入對方的西元生日</div>
      <div class="input-block">
        <div class="flex flex-row justify-center items-center">
          <input
            @focus="focus"
            class="input"
            type="text"
            :value="input"
            readonly
          />
          <div
            class="btn-year"
            :class="{ active: selectedType == 'year' }"
            @click="selectedType = 'year'"
          >
            查詢
            <div>年</div>
          </div>
          <div
            class="btn-day"
            :class="{ active: selectedType == 'day' }"
            @click="selectedType = 'day'"
          >
            查詢
            <div>月日</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 flex flex-col justify-center">
      <DefenceYear
        class="h-full mt-16"
        v-if="isDateValid && selectedType == 'year' && showPad == false"
        :birth="input"
      ></DefenceYear>
      <Calendar
        class="h-full mt-16"
        v-if="isDateValid && selectedType == 'day' && showPad == false"
        :detail="false"
        :userId="profile.userId"
        :birth="input"
        @modalEvent="modalEvent"
        @selectMurphy="selectMurphy"
      />
    </div>
    <InputPad
      ref="inputPad"
      v-show="showPad"
      @submit="submit"
      @input="inputChange"
      :defaultInput="input"
    ></InputPad>

    <Modal v-if="modal.show" :payload="modal" @close="modal.show = false" />
    <Card v-if="showCard" :payload="selectedMurphy" @close="closeCard" />
    <Card
      v-if="first"
      :popID="19"
      :full="true"
      :payload="{ day: { cardUrl: $store.state.images['POP-D.png'] } }"
      @close="first = false"
    />
  </div>
</template>

<script>
import Calendar from "@/components/calendar";
import InputPad from "@/components/inputPad";
import Modal from "@/components/modal";
import Card from "@/components/card";
import DefenceYear from "@/views/DefenceYear";
import ModalEvent from "@/mixins/modal";
import Util from "@/mixins/util";
const dateFns = require("date-fns");
const liff = window.liff;

export default {
  name: "Birth",
  components: {
    InputPad,
    Modal,
    DefenceYear,
    Calendar,
    Card,
  },
  mixins: [ModalEvent, Util],
  data: function () {
    return {
      ready: false,
      first: true,
      showPad: true,
      liffId: "1656896964-aEb99WBV",
      title: "",
      input: "",
      selectedType: "year",
      showCard: false,
      selectedMurphy: null,
    };
  },
  async created() {
    await this.setLiff(this.liffId);
    this.ready = true;
  },
  computed: {
    isDateValid() {
      let date = this.input;
      if (date.length != 8) {
        return false;
      }
      let year = parseInt(date.slice(0, 4));
      let month = parseInt(date.slice(4, 6));
      let day = parseInt(date.slice(6, 8));
      if (
        year < 1942 ||
        year > 2022 ||
        month < 0 ||
        month > 12 ||
        day < 0 ||
        day > 31
      ) {
        return false;
      }

      let checkDate = new Date();
      checkDate.setFullYear(year, month - 1, day);
      if (
        checkDate.getFullYear() != year ||
        checkDate.getMonth() != month - 1 ||
        checkDate.getDate() != day
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    focus() {
      this.showPad = true;
      this.$refs.inputPad.clear();
    },
    closeCard() {
      this.showCard = false;
    },
    selectMurphy(data) {
      console.log(data);
      if (data.day && data.day.cardUrl) {
        this.selectedMurphy = data;
        this.showCard = true;
      }
    },
    inputChange(input) {
      this.input = input;
    },
    async submit() {
      if (this.isDateValid) {
        this.showPad = false;
      } else {
        this.modalEvent("birthError");
      }

      // todo

      // await liff.sendMessages([
      //   {
      //     type: "text",
      //     text: date,
      //   },
      // ]);

      // date = date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
      // console.log(date);
      // liff.closeWindow();
    },
  },
};
</script>
<style lang="scss" scoped>
.birth {
  font-family: "Noto Sans TC", sans-serif !important;
  text-align: center;
  background: #001a34;
  height: 100vh;
  .title {
    color: #00ffff;
    font-size: 24px;
    margin: 0px 0px 10px 0px;
    padding-top: 10px;
  }
  .desc {
    color: #666;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 500;
    span {
      font-size: 28px;
    }
  }
  .limit {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    span {
      margin: 0px 10px;
    }
  }
  .sample {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
  }

  .input-block {
    .input {
      border: 1px solid #666;
      border-radius: 24px;
      width: 190px;
      height: 46px;
      line-height: 50px;
      font-size: 26px;
      text-align: center;
      margin: 0 5px;
      padding-bottom: 4px;
    }
    .btn-year,
    .btn-day {
      color: #00ffff;
      border-radius: 24px;
      border: 1px solid #00ffff;
      line-height: 20px;
      font-size: 14px;
      height: 46px;
      width: 58px;
      margin: 0 5px;
      div {
        font-size: 20px;
      }
      &.active {
        background: #00ffff;
        color: #001a34;
      }
    }
  }
}
</style>
