<template>
  <div class="defence-year">
    <div class="search-block flex items-center">
      <div class="w-3/4 flex flex-col">
        <div class="flex mb-1">
          <div class="word flex items-center justify-center">憂</div>
          <div class="word flex items-center justify-center">鬱</div>
          <div class="word flex items-center justify-center">年</div>
        </div>
        <div class="range">最多可查詢前後25年</div>
      </div>
      <div class="w-1/4 flex flex-col items-center">
        <div
          class="btn-filter current-year flex justify-center items-center"
          @click="gotoCurrentYear"
        >
          今年
        </div>
      </div>
    </div>
    <div class="wrapper" ref="wrapper">
      <div
        class="year"
        v-for="year of years"
        @click="selectYear(year)"
        :class="{
          currentYear: year.year == currentYear,
          selectedYear: year.year == selectedYear,
          yellow: year.light == 'n1',
          green: year.light == 'n2',
        }"
      >
        <div class="label">
          <div v-if="year.year == currentYear">今年</div>
          <div v-else>{{ year.year - birthYear }}</div>
        </div>
        <div class="outter-cycle">
          <div class="inner-cycle">
            {{ year.year }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="cardUrl"
      class="
        h-full
        w-full
        absolute
        z-10
        top-0
        bg-black
        flex flex-col
        items-center
        justify-center
      "
    >
      <div
        class="rounded-full bg-white self-end text-black mr-2"
        @click="closeImage()"
      >
        <mdicon name="close" width="30" height="30" />
      </div>
      <div><img :src="cardUrl" alt="" /></div>
    </div>
    <notifyModal
      :popID="15"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-5.png'],
      }"
    ></notifyModal>
  </div>
</template>

<script>
import notifyModal from "@/components/notifyModal.vue";
import Util from "@/mixins/util";
import dayjs from "dayjs";
export default {
  name: "DefenceYear",
  props: ["birth"],
  mixins: [Util],
  components: { notifyModal },
  data: () => ({
    // liffId: "1653437596-Dbo6p5B5",
    // title: "",
    first: true,
    years: [],
    currentYear: null,
    selectedYear: null,
    checkbox: false,
    hasNotified: false,
    cardUrl: null,
    ready: false,
  }),
  async created() {
    this.years = (await this.getData()).data;
    this.currentYear = dayjs().get("year");
    this.selectedYear = this.currentYear;
  },
  updated() {
    if (this.first) {
      this.gotoCurrentYear();
    }
  },
  computed: {
    // dayjs() {
    //   return dayjs;
    // },
    birthYear() {
      return parseInt(this.birth.substring(0, 4));
    },
  },
  methods: {
    closeImage() {
      this.cardUrl = null;
    },

    async getData() {
      let url = `${this.apiURL}/quick_murphy/birth_date/${this.birth}/year`;
      let data = await fetch(url);
      data = await data.json();
      return data;
    },
    selectYear(year) {
      if (year.light == "0") return;
      this.cardUrl = year.cardUrl;
      this.selectedYear = year.year;
    },
    gotoCurrentYear() {
      let width =
        (window.screen.width / 4 - 10 + 6 * 2) * (this.years.length - 4);

      this.$refs.wrapper.scrollLeft = width / 2;
      this.first = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.defence-year {
  color: #fff;

  .search-block {
    padding: 16px;
    margin: 0px 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    border: 2px solid #48ff4857;
    box-shadow: 0px 0px 12px #00ff00;
    background-color: #1a1b22;
    .word {
      color: #00ff00;
      font-weight: bolder;
      border: 1px solid #00ff00;
      border-radius: 100%;
      height: 32px;
      width: 32px;
      margin: 0 2px;
      font-size: 20px;
    }
    .range {
      color: #6b7280;
      font-size: 20px;
      font-weight: lighter;
      text-align: left;
    }

    .current-year {
      border-radius: 30px;
      padding: 4px 10px;
      font-size: 18px;
      color: #00ff00;
      border: 2px solid #00ff00;
      &.active {
        // background-color: #cd6161;
        // color: #fff;
      }
    }
  }
  .wrapper {
    display: flex;
    overflow: auto;
    flex-direction: row;
    background-color: #121828;
    margin-top: 20px;
    padding: 40px 0 60px 0;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #00ff00;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    &::-webkit-scrollbar-track:active {
      background: #333333;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  .year {
    width: calc(25vw - 10px);
    margin: 6px;
    flex: 0 0 auto;
    text-align: center;
    .label {
      font-size: 24px;
      margin-bottom: 12px;
      color: #6b7280;
    }

    .outter-cycle {
      font-size: 28px;
      font-weight: lighter;
      border: 2px solid transparent;
      border-radius: 100%;
      width: calc(25vw - 10px);
      height: calc(25vw - 10px);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1f2937;
    }
    .inner-cycle {
      font-family: "Rubik", sans-serif !important;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(25vw - 24px);
      height: calc(25vw - 24px);
      background-color: #1f2937;
      border-radius: 100%;
      color: #6b7280;
    }

    &.currentYear {
      .label {
        color: #fff;
      }
      &.green .inner-cycle {
        background-color: #004000;
      }
      &.yellow .inner-cycle {
        background-color: #404000;
      }
    }
    &.green {
      .outter-cycle {
        background: transparent;
        border-color: #00ff00;
      }
      .inner-cycle {
        color: #00ff00;
      }
      &.selectedYear {
        .outter-cycle {
          background: transparent;
          border-color: #00ff00;
        }
        .inner-cycle {
          color: #000;
          background-color: #00ff00;
        }
      }
    }

    &.yellow {
      .outter-cycle {
        background: transparent;
        border-color: #ffff00;
      }
      .inner-cycle {
        color: #ffff00;
      }
      &.selectedYear {
        .outter-cycle {
          background: transparent;
          border-color: #ffff00;
        }
        .inner-cycle {
          color: #000;
          background-color: #ffff00;
        }
      }
    }
  }
}
</style>