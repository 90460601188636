<template>
  <div class="input-pad">
    <img
      v-if="!showCalendar"
      class="pad"
      src="https://api.murphysday.com/uploads/images/liff-pad.png"
      usemap="#image_map"
      width="1080"
    />
    <map name="image_map">
      <area @click="padEnter('1')" coords="160, 125, 80" shape="circle" />
      <area @click="padEnter('2')" coords="350, 125, 80" shape="circle" />
      <area @click="padEnter('3')" coords="540, 125, 80" shape="circle" />
      <area @click="padEnter('4')" coords="160, 305, 80" shape="circle" />
      <area @click="padEnter('5')" coords="350, 305, 80" shape="circle" />
      <area @click="padEnter('6')" coords="540, 305, 80" shape="circle" />
      <area @click="padEnter('7')" coords="160, 480, 80" shape="circle" />
      <area @click="padEnter('8')" coords="350, 480, 80" shape="circle" />
      <area @click="padEnter('9')" coords="540, 480, 80" shape="circle" />
      <area @click="padEnter('0')" coords="82, 617, 615, 775" shape="rect" />
      <area
        @click="padEnter('remove')"
        coords="655, 46, 999, 203"
        shape="rect"
      />
      <area @click="padEnter('ok')" coords="656, 236, 1003, 622" shape="rect" />
    </map>
  </div>
</template>

<script>
const dateFns = require("date-fns");
import $ from "jquery";

export default {
  name: "InputPad",
  data: function () {
    return {
      input: "",
    };
  },
  props: ["showCalendar", "defaultInput"],
  mounted() {
    setTimeout(this.resizeMap, 10);
    this.input = this.defaultInput || "";
  },
  methods: {
    clear() {
      this.input = "";
      this.$emit("input", this.input);
    },
    padEnter(n) {
      switch (n) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
          if (this.input.length < 8) {
            this.input += n;
            this.$emit("input", this.input);
          }
          break;
        case "remove":
          this.input = this.input.slice(0, -1);
          this.$emit("input", this.input);
          break;
        case "ok":
          this.$emit("submit", this.input);
          break;
      }
    },
    resizeMap() {
      if (!$("<area coords=1>").attr("coords")) return;

      // find all images attached to a map
      var imgs = $("img[usemap]");

      // store initial coords of each <area>
      $("area").each(function (i, v) {
        var area = $(v);
        area.data("coords", area.attr("coords"));
      });

      // on window resize, iterate through each image
      // and scale its map areas
      $(window)
        .bind("resize.scaleMaps", function () {
          imgs.each(function (i, v) {
            scaleMap($(v));
          });
        })
        .trigger("resize.scaleMaps");

      // find image scale by comparing offset width with width attribute
      // if the scale has changed or not set,
      // scale its map's areas by this factor
      // and store the new scale using $.data()
      function scaleMap(img) {
        var mapName = img.attr("usemap").replace("#", ""),
          map = $('map[name="' + mapName + '"]'),
          imgScale = img.width() / img.attr("width");

        if (imgScale !== img.data("scale")) {
          map.find("area").each(function (i, v) {
            var area = $(v),
              coords = area.data("coords").split(","),
              newCoords = [];
            $.each(coords, function (j, w) {
              newCoords.push(w * imgScale);
            });
            area.attr("coords", newCoords.join(","));
          });
          img.data("scale", imgScale);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-pad {
  text-align: center;
  z-index: 9999;
}

.pad {
  position: fixed;
  bottom: 0;
  left: 0;
  // background-color: #3e3b5b;
  width: 100vw;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
table {
  width: 100%;
  height: 100%;
  border-spacing: 20px;
  td {
    font-family: "Rubik", sans-serif !important;
    border: 1px solid red;
    span {
      border-radius: 25px;
      background-color: #000;
      color: #f15a24;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.ok {
  font-family: "Oswald", sans-serif !important;
}
.backspace {
  width: 30px;
}
</style>
